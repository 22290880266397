import NextImage from 'next/image'
import { ComponentProps, FC } from 'react'

import { StrapiImage } from 'api/strapi/StrapiRecord'

export type ImageProps = {
  item?: StrapiImage
} & Omit<ComponentProps<typeof NextImage>, 'src'>

export const Image: FC<ImageProps> = ({ item, fill, ...props }) => {
  if (!item) return null

  return (
    <NextImage
      src={item.url}
      width={fill ? undefined : item.width}
      height={fill ? undefined : item.height}
      fill={fill}
      {...props}
    />
  )
}
