import NextImage from 'next/image'
import ReactPlayer from 'react-player/lazy'

import { Markdown } from 'components/markdown'
import { Heading } from 'components/heading'
import { cn } from 'utils'
import { WelcomeSectionContainer } from 'components/welcome-section-container'
import { getAdaptiveImageSize } from 'utils/media'
import { NoSsr } from 'components/no-ssr'

import styles from './welcome-section.module.css'

export interface WelcomeSectionProps {
  body?: string
  title?: string
  image?: string
  video?: string
  classNameImage?: string
  className?: string
  withBgSun?: boolean
  bgImage?: string
  classNameTitle?: string
  classNameBody?: string
  classNameContent?: string
  classNameBgImage?: string
  classNameSection?: string
  altText?: string
  bgImageAltText?: string
  customImage?: JSX.Element
}

export const WelcomeSection: FCRC<WelcomeSectionProps> = ({
  body,
  image,
  video,
  classNameImage,
  className,
  title,
  withBgSun = true,
  children,
  bgImage,
  classNameTitle,
  classNameBody,
  classNameContent,
  classNameBgImage,
  classNameSection,
  altText,
  bgImageAltText,
  customImage, // needed to avoid <picture /> default wrapper tag, since it makes mess for gifs for specific tablet breakpoints
}) => {
  const imageSize = getAdaptiveImageSize({
    default: '55vw',
    tablet: '90vw',
    mobile: '90vw',
  })

  if (!body && !title) return null

  const defaultImage = image ? (
    <picture className={cn(styles.imageContainer, classNameImage)}>
      <NextImage
        src={image}
        width={676}
        height={515}
        quality={90}
        sizes={imageSize}
        alt={altText || title}
        className={styles.image}
        priority={true}
      />
    </picture>
  ) : null

  const img = customImage ? customImage : defaultImage

  return (
    <WelcomeSectionContainer
      withBgSun={withBgSun}
      classNameSection={cn(styles.section, classNameSection)}
      className={className}
      bgImage={bgImage}
      classNameBgImage={classNameBgImage}
      bgImageAltText={bgImageAltText}
      altText={altText}
    >
      <div className={cn(styles.content, classNameContent)}>
        <Heading theme="h1" className={classNameTitle}>
          {title}
        </Heading>
        <Markdown className={classNameBody}>{body}</Markdown>

        {children}
      </div>

      {img}

      {video && (
        <NoSsr>
          <ReactPlayer
            controls
            url={video}
            className={styles.video}
            playing={true}
            muted={true}
            loop={true}
          />
        </NoSsr>
      )}
    </WelcomeSectionContainer>
  )
}
