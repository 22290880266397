import { FC } from 'react'
import { usePathname } from 'next/navigation'

import { Article } from 'api'
import { Image } from 'components/image'
import { routes } from 'utils/routes'
import { cn } from 'utils'
import { Heading } from 'components/heading'
import { Link } from 'components/links'
import { TagsList } from 'components/tag'
import { checkForLandingPage } from 'components/header/default-header/utils'
import { getBasicArticlesRoute } from 'sections/articles/page-reusable-data/funcs'

import { getAdaptiveImageSize } from '../../utils/media'
import classes from './article-tile.module.css'

export interface ArticleTileProps {
  item: Article
  tagLinkScroll?: boolean
  className?: string
  featured?: boolean
  theme?: 'tile' | 'featured'
}

const imageSize = getAdaptiveImageSize({
  default: '20vw',
  tablet: '25vw',
  mobile: '95vw',
})

export const ArticleTile: FC<ArticleTileProps> = ({
  item: article,
  className,
  featured,
  tagLinkScroll,
  theme = 'tile',
  ...props
}) => {
  const pathname = usePathname()
  const { isLandingPage, landingPageType } = checkForLandingPage(pathname)

  const basicArticlesRoute = getBasicArticlesRoute(isLandingPage, landingPageType)

  const href = routes.article(article.slug, basicArticlesRoute)

  return (
    <article {...props} className={cn(classes.article, classes[theme], className)}>
      <Link className={classes.imageContainer} href={href} tabIndex={-1}>
        {article.cover && (
          <Image
            item={article.cover}
            alt={article.cover.alternativeText ?? ''}
            fill
            sizes={imageSize}
            className={classes.image}
          />
        )}
      </Link>

      <div>
        <Link href={href} className={classes.title}>
          <Heading theme="h5" component="h5" className={classes.title}>
            {article.title}
          </Heading>
        </Link>

        {article.tags && (
          <TagsList items={article.tags} className={classes.tags} tagLinkScroll={tagLinkScroll} />
        )}

        <p className={classes.description}>{article.description}</p>
      </div>
    </article>
  )
}
