import { FC } from 'react'
import ReactMarkdown from 'react-markdown'
import { ReactMarkdownOptions } from 'react-markdown/lib/react-markdown'
import rehypeRaw from 'rehype-raw'
import remarkGfm from 'remark-gfm'

import { cn } from 'utils'

import { MarkdownAnchor } from './MarkdownAnchor'
import styles from './markdown.module.css'

const ALLOWED_ELEMENTS = [
  'u',
  'p',
  'b',
  'strong',
  'i',
  'em',
  'a',
  'ul',
  'ol',
  'li',
  'span',
  'hr',
  'img',
  'code',
  // headings
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  // quotes:
  'blockquote',
  'q',
  // strikethrough:
  'del',
  // tables:
  'table',
  'tr',
  'td',
  'th',
  'thead',
  'tbody',
  'tfoot',
  'caption',
  'col',
]

const REHYPE_PLUGINS = [
  rehypeRaw, // allow HTML
]

const REMARK_PLUGINS = [
  remarkGfm, // autolink literals, footnotes, strikethrough, tables, tasklists
]

export type MarkdownProps = Omit<ReactMarkdownOptions, 'children'> & {
  allowTarget?: boolean
  children?: string | null
}
export const Markdown: FC<MarkdownProps> = ({
  children,
  components,
  className,
  allowTarget,
  ...props
}) => {
  return children ? (
    <ReactMarkdown
      {...props}
      className={cn(styles.markdown, className)}
      rehypePlugins={REHYPE_PLUGINS}
      remarkPlugins={REMARK_PLUGINS}
      allowedElements={ALLOWED_ELEMENTS}
      components={{
        a: (d) => MarkdownAnchor({ ...d, allowTarget: Boolean(allowTarget) }),
        ...components,
      }}
    >
      {children}
    </ReactMarkdown>
  ) : null
}
