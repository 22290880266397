import { FC } from 'react'

import { Article } from 'api'
import { ArticleTile } from 'components/article'
import { cn } from 'utils'

import styles from './articles-list.module.css'

export interface ArticlesListProps {
  items: Article[]
  tagLinkNextScroll?: boolean
  className?: string
  id?: string
}
export const ArticlesList: FC<ArticlesListProps> = ({
  items,
  className,
  tagLinkNextScroll,
  ...props
}) => {
  return (
    <ul {...props} className={cn(styles.list, className)}>
      {items.map((article) => (
        <li className={styles.item} key={article.id}>
          <ArticleTile item={article} tagLinkScroll={tagLinkNextScroll} />
        </li>
      ))}
    </ul>
  )
}
