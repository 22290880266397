import { FC } from 'react'

import { Section } from 'components/section'
import { Article } from 'api'
import { Heading } from 'components/heading'
import { LinkButton } from 'components/links'
import { routes } from 'utils/routes'
import { ArticlesList } from 'components/article'

import classes from './articles-section.module.css'

interface ArticlesSectionProps {
  title: string
  articles: Article[]
}

export const ArticlesSection: FC<ArticlesSectionProps> = ({ articles, title }) => {
  if (!articles.length) return null

  return (
    <div className={classes.container}>
      <Section className={classes.section}>
        <Heading theme="h2" component="h2" className={classes.title}>
          {title}
        </Heading>
        <LinkButton theme="accentLinkToMobileBtn" href={routes.articles} className={classes.link}>
          View all posts
        </LinkButton>
        <ArticlesList items={articles} className={classes.articles} />
      </Section>
    </div>
  )
}
