import { FC } from 'react'

import { Tag as TagModel } from 'api'
import { Tag } from 'components/tag'
import { cn } from 'utils'

import styles from './tags-list.module.css'

interface Props {
  items: TagModel[]
  tagLinkScroll?: boolean
  className?: string
  classNameTag?: string
}
export const TagsList: FC<Props> = ({
  items,
  className,
  tagLinkScroll,
  classNameTag,
  ...props
}) => {
  return (
    <ul {...props} className={cn(styles.list, className)}>
      {items.map((tag) => (
        <li className={styles.item} key={tag.id}>
          <Tag
            slug={tag.slug}
            title={tag.title}
            className={classNameTag}
            tagLinkScroll={tagLinkScroll}
          />
        </li>
      ))}
    </ul>
  )
}
