import { FC } from 'react'

import { Heading } from 'components/heading'
import { Section } from 'components/section'
import { routes } from 'utils/routes'
import { Link } from 'components/links'
import { cn } from 'utils'
import { Markdown } from 'components/markdown'

import classes from './additional-page-nav.module.css'

type Page = 'aboutUs' | 'meetTheTeam' | 'joinUs'

const NAV_ITEMS: Record<Page, { name: string; link: string }> = {
  aboutUs: {
    link: routes.aboutUs,
    name: 'About us',
  },
  meetTheTeam: {
    link: routes.meetTheTeam,
    name: 'Meet the team',
  },
  joinUs: {
    name: 'Join us',
    link: routes.joinUs,
  },
}

interface AdditionalPageNavProps {
  page: Page
  welcomeContent: string
  welcomeContentTitle: string
}

export const AdditionalPageNav: FC<AdditionalPageNavProps> = ({
  page,
  welcomeContentTitle,
  welcomeContent,
}) => {
  return (
    <div className={classes.welcomeContainer}>
      <Section theme="outerGap" className={classes.section}>
        <nav>
          <Heading theme="h5" component="h5" className={classes.navTitle}>
            Also in this section
          </Heading>
          <ul className={classes.list}>
            {Object.entries(NAV_ITEMS).map(([key, { name, link }]) => {
              const isActive = key === page

              return (
                <li key={key} className={cn(classes.listItem, isActive && classes.activeItem)}>
                  <Link href={link} className={cn(classes.link, isActive && classes.activeLink)}>
                    {name}
                  </Link>
                </li>
              )
            })}
          </ul>
        </nav>
        <div className={classes.welcomeSection}>
          <h1 className={classes.pageName}>{NAV_ITEMS[page].name}</h1>
          <Heading theme="h1" component="h2" className={classes.welcomeTitle}>
            {welcomeContentTitle}
          </Heading>
          <Markdown className={classes.welcomeContent}>{welcomeContent}</Markdown>
        </div>
      </Section>
    </div>
  )
}
