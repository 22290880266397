import { FC } from 'react'
import LibSkeleton, { SkeletonProps as LibSkeletonProps } from 'react-loading-skeleton'

import 'react-loading-skeleton/dist/skeleton.css'
import { cn } from 'utils'

import classes from './skeleton.module.css'

interface SkeletonProps extends LibSkeletonProps {
  theme?: 'accent'
}

export const Skeleton: FC<SkeletonProps> = ({ className, theme, ...props }) => {
  return (
    <LibSkeleton
      {...props}
      className={cn(theme && classes[`${theme}Theme`], classes.skeleton, className)}
    />
  )
}
