import { FC, useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'

import { nest } from 'api'
import { BuyNowButton } from 'components/buy-now-button'
import { Markdown } from 'components/markdown'
import { Section } from 'components/section'
import { LinkButton } from 'components/links'
import { routes } from 'utils/routes'
import { cn, getRandomIntInclusive } from 'utils'
import { Heading } from 'components/heading'
import { Image } from 'components/image'
import { Skeleton } from 'components/skeleton'
import styles from 'sections/common/cta-section/cta-section.module.css'

import { getAdaptiveImageSize } from '../../../utils/media'
import { QUERY_KEY } from '../../../const/query'

interface CtaSectionProps {
  className?: string
  withDivider?: boolean
  altText?: string
}

const imageSizes = getAdaptiveImageSize({
  default: '400px',
  tablet: '282px',
  mobile: '42vw',
})

export const CTASection: FC<CtaSectionProps> = ({ className, withDivider = true, altText }) => {
  const { error, data, isLoading } = useQuery([QUERY_KEY.CTA], () => nest.cta.find(), {
    refetchOnMount: false,
    refetchIntervalInBackground: false,
    refetchOnWindowFocus: false,
    refetchInterval: false,
  })

  const randomCTA = useMemo(() => {
    if (data) {
      const randomIndex = getRandomIntInclusive(0, data.items.length - 1)
      return data.items[randomIndex]
    }
  }, [data])

  const isNotFoundCta = !isLoading && !randomCTA

  if (error || isNotFoundCta) {
    return null
  }

  return (
    <Section
      theme="outerGap"
      className={cn(styles.section, withDivider && styles.divider, className)}
    >
      <div className={styles.image}>
        {isLoading ? (
          <Skeleton circle width="100%" height="100%" />
        ) : (
          <Image
            alt={altText || randomCTA?.image?.alternativeText || 'CTA Image'}
            item={randomCTA?.image}
            fill
            className={styles.image}
            sizes={imageSizes}
          />
        )}
      </div>

      <div className={styles.content}>
        <Heading theme="h2">
          {isLoading ? <Skeleton width="100%" height="1em" /> : randomCTA?.title}
        </Heading>
        {isLoading ? (
          <>
            <Skeleton width="100%" height="1em" />
            <Skeleton width="100%" height="1em" />
            <Skeleton width="50%" height="1em" />
          </>
        ) : (
          <Markdown>{randomCTA?.content}</Markdown>
        )}

        <footer className={styles.footer}>
          {isLoading ? (
            <>
              <Skeleton
                width={145}
                height="3rem"
                className={styles.primaryButton}
                borderRadius={30}
              />
              <Skeleton width={208} height="3rem" borderRadius={30} />
            </>
          ) : (
            <>
              <BuyNowButton
                toIgnoreSessionRole={true}
                className={cn(styles.primaryButton, styles.button)}
              />
              <LinkButton
                href={routes.howItWorks}
                theme="secondaryToMobileBtn"
                className={styles.button}
              >
                See How It Works
              </LinkButton>
            </>
          )}
        </footer>
      </div>
    </Section>
  )
}
