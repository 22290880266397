import { FC } from 'react'
import { usePathname } from 'next/navigation'

import { Link } from 'components/links'
import { routes } from 'utils/routes'
import { cn } from 'utils'
import { checkForLandingPage } from 'components/header/default-header/utils'
import { getBasicArticlesRoute } from 'sections/articles/page-reusable-data/funcs'

import classes from './tag.module.css'

export interface TagProps {
  className?: string
  tagLinkScroll?: boolean
  slug: string
  title: string
}

export const Tag: FC<TagProps> = ({ className, tagLinkScroll = true, slug, title }) => {
  const pathname = usePathname()
  const { isLandingPage, landingPageType } = checkForLandingPage(pathname)

  const basicArticlesRoute = getBasicArticlesRoute(isLandingPage, landingPageType)

  return (
    <Link
      href={routes.articlesBySlug(slug, basicArticlesRoute)}
      className={cn(classes.tag, className)}
      scroll={tagLinkScroll}
    >
      {title}
    </Link>
  )
}
