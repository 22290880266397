import { AnchorHTMLAttributes, DetailedHTMLProps, ReactNode } from 'react'

interface IMarkdownAnchorProps {
  target?: string
  children: ReactNode | ReactNode[]
  href?: string
  allowTarget: boolean
}

const isInnerSiteLink = (link: string): boolean => {
  const url = process.env.NEXT_PUBLIC_SELF_URL || process.env.NEXT_PUBLIC_BASE_URL
  return Boolean(link.startsWith('/') || (url && link.startsWith(url)))
}

const EXTERNAL_LINK_PROPS: AnchorHTMLAttributes<HTMLAnchorElement> = {
  target: '_blank',
  rel: 'nofollow noopener noreferrer',
}

export const MarkdownAnchor = ({ children, href, allowTarget, target }: IMarkdownAnchorProps) => {
  if (!href) {
    return null
  }

  const isInner = isInnerSiteLink(href)
  return (
    <a
      href={href}
      {...(!isInner && EXTERNAL_LINK_PROPS)}
      {...(allowTarget && target && { target })}
    >
      {children}
    </a>
  )
}
