import { FC } from 'react'
import { usePathname } from 'next/navigation'

import { WelcomeSectionContainer } from 'components/welcome-section-container'
import { Heading } from 'components/heading'
import { cn } from 'utils'
import { Markdown } from 'components/markdown'
import { TagsList } from 'components/tag'
import { Article } from 'api'
import { Image } from 'components/image'
import { LinkButton } from 'components/links'
import { routes } from 'utils/routes'
import { checkForLandingPage } from 'components/header/default-header/utils'
import { getBasicArticlesRoute } from 'sections/articles/page-reusable-data/funcs'

import { getAdaptiveImageSize } from '../../../utils/media'
import classes from './welcome-article-section.module.css'

interface WelcomeArticleSectionProps {
  article: Article
  withLinkToArticle?: boolean
}

const imageSizes = getAdaptiveImageSize({
  default: '600px',
  tablet: '60vw',
  mobile: '75vw',
})

export const WelcomeArticleSection: FC<WelcomeArticleSectionProps> = ({
  article,
  withLinkToArticle,
}) => {
  const pathname = usePathname()
  const { isLandingPage, landingPageType } = checkForLandingPage(pathname)

  const basicArticlesRoute = getBasicArticlesRoute(isLandingPage, landingPageType)

  return (
    <WelcomeSectionContainer className={classes.container} classNameSection={classes.section}>
      <div className={cn(classes.content)}>
        <Heading theme="h1" component="h1" className={classes.title}>
          {article.title}
        </Heading>
        {!!article.tags?.length && (
          <TagsList
            items={article.tags}
            className={classes.tags}
            classNameTag={classes.tag}
            tagLinkScroll={false}
          />
        )}
        <Markdown className={classes.markdown}>{article.description}</Markdown>

        {withLinkToArticle && (
          <LinkButton
            className={classes.link}
            href={routes.articleContent(article.slug, basicArticlesRoute)}
            theme="secondary"
          >
            Read More
          </LinkButton>
        )}
      </div>

      <div className={classes.imagePath}>
        <div className={classes.imageContainer}>
          <Image
            item={article.cover}
            fill
            alt={article.title}
            className={classes.image}
            sizes={imageSizes}
          />
        </div>
      </div>
    </WelcomeSectionContainer>
  )
}
