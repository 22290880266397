import { GetServerSideProps, GetStaticPaths, GetStaticProps } from 'next'
import { ParsedUrlQuery } from 'querystring'

import { Article, SearchArticleModel, strapi, Tag } from 'api'
import { StrapiPagination } from 'api/strapi'
import { PAGE_QUERY_NAME } from 'const/list'
import { routes } from 'utils/routes'
import { IS_DEVELOPMENT_MODE } from 'const/config'
import { REVALIDATION_ERROR_TIMEOUT, REVALIDATION_TIMEOUT } from 'const/config'
import { BackendError } from 'api/strapi/BackendError'
import { getBasicRoute, LandingPageTypeEnum } from 'components/header/default-header/utils'

interface IArticlesServerSideProps {
  pagination: StrapiPagination
  articles: Article[]
  featuredArticle?: Article | null
  tags: Tag[]
  initSort: string | null
  initTag: string | null
  initSearch: string | null
}

interface Query extends ParsedUrlQuery, Omit<SearchArticleModel, 'page'> {
  [PAGE_QUERY_NAME]?: string
}

export const articlesServerSidePropsFunc: GetServerSideProps<
  IArticlesServerSideProps,
  Query
> = async (context) => {
  const { [PAGE_QUERY_NAME]: page = 1, sort, tagSlug, search } = context.query ?? {}

  const queryTags = tagSlug && String(tagSlug)
  const querySort = sort && String(sort)
  const querySearch = search && String(search)

  try {
    const [{ items, pagination }, featuredArticle, tags] = await Promise.all([
      strapi.articles.articlesPageSearch({
        page: Number(page),
        sort: querySort,
        tagSlug: queryTags,
        search: querySearch,
      }),
      strapi.articles.findFeatured(),
      strapi.tags.find({
        pagination: {
          limit: -1,
        },
      }),
    ])

    const notFoundArticles = pagination.pageCount === 0
    const isNotValidPage = !notFoundArticles && pagination.page > pagination.pageCount

    if (isNotValidPage) {
      return {
        redirect: {
          destination: routes.articles,
          permanent: true,
        },
      }
    }

    return {
      props: {
        articles: items,
        pagination,
        featuredArticle: featuredArticle ?? null,
        tags: tags.items,
        initTag: queryTags ?? null,
        initSearch: querySearch ?? null,
        initSort: querySort ?? null,
      },
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    IS_DEVELOPMENT_MODE && console.log('Articles Error: ', e)
    return {
      redirect: {
        destination: routes.articles,
        permanent: true,
      },
    }
  }
}

interface IArticleStaticProps {
  article: Article
}

export const articleStaticPropsFunc: GetStaticProps<IArticleStaticProps> = async (context) => {
  const { slug } = context.params ?? {}
  if (!slug)
    return {
      notFound: true,
      revalidate: REVALIDATION_ERROR_TIMEOUT,
    }

  try {
    const [article] = await Promise.all([strapi.articles.findOneBySlug(String(slug))])

    if (!article) {
      return {
        notFound: true,
        revalidate: REVALIDATION_ERROR_TIMEOUT,
      }
    }

    return {
      revalidate: REVALIDATION_TIMEOUT,
      props: {
        article,
      },
    }
  } catch (error) {
    if ((error as BackendError).notFound) {
      return {
        notFound: true,
        revalidate: REVALIDATION_ERROR_TIMEOUT,
      }
    }
    throw error
  }
}

export const articleStaticPathsFunc: GetStaticPaths = async () => {
  const slugs = await strapi.articles.listSlugs()
  return {
    fallback: 'blocking',
    paths: slugs.map((slug) => ({
      params: {
        slug: String(slug),
      },
    })),
  }
}

export const getBasicArticlesRoute = (
  isLandingPage: boolean,
  landingPageType: LandingPageTypeEnum | null,
) => (isLandingPage ? getBasicRoute(landingPageType) + routes.articles : routes.articles)
