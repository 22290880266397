import NextImage from 'next/image'

import { cn } from 'utils'
import { Section } from 'components/section'

import classes from './welcome-section-container.module.css'

export interface WelcomeSectionContainerProps {
  className?: string
  classNameSection?: string
  withBgSun?: boolean
  bgImage?: string
  classNameBgImage?: string
  bgImageAltText?: string
}

export const WelcomeSectionContainer: FCRC<WelcomeSectionContainerProps> = ({
  withBgSun = true,
  children,
  className,
  classNameSection,
  bgImage,
  classNameBgImage,
  bgImageAltText,
}) => {
  return (
    <div className={cn(classes.mainContainer, className)}>
      <Section className={cn(classes.section, withBgSun && classes.bgSun, classNameSection)}>
        {children}
      </Section>
      {bgImage && (
        <NextImage
          alt={bgImageAltText || 'background image'}
          fill
          src={bgImage}
          blurDataURL={bgImage}
          quality={100}
          sizes="100vw"
          className={cn(classes.bgImage, classNameBgImage)}
          priority={true}
          placeholder="blur"
        />
      )}
    </div>
  )
}
